import { CarFinderConstants, FilterActionType } from '@/constants/carFinder';
import { StateCreator } from 'zustand';

import {
  FuelType,
  type FilterAction,
  type FilterState,
} from '@/types/CarFinder';

import { TransmissionType, VehicleBodyType } from '@/lib/gql/graphql';

import { type CarFinderSlice } from '@/store/typings';

const {
  DefaultValues: { ArbitraryOverflowPrice, MaxPrice, MinYear },
} = CarFinderConstants;

// This is the initial state for the car finder filter.
// No filters are applied by default.
// If users arrive on page with query parameters, they will replace the default values on a per-filter basis.
// Filters values also return to default when the "Clear All" button is clicked.
const initialFilterState: FilterState = {
  adaptive_cruise_control: false,
  android_auto: false,
  apple_carplay: false,
  body_style: [],
  combined_mpg: 0,
  fuel_type: [],
  horsepower: 0,
  make: '',
  model: '',
  motor_trend_score: false,
  panoramic_roof: false,
  pricing: {
    max_price: ArbitraryOverflowPrice,
    min_price: 0,
  },
  seating_capacity: 0,
  transmission_type: '',
  year_range: {
    max_year: new Date().getFullYear() + 1,
    min_year: MinYear,
  },
};

// This is the reducer for the car finder filter.
// It updates the filter state based on the action type.
function filterReducer(state: FilterState, action: FilterAction): FilterState {
  switch (action.type) {
    case FilterActionType.SetAdaptiveCruiseControl:
      return { ...state, adaptive_cruise_control: action.payload };
    case FilterActionType.SetAndroidAuto:
      return { ...state, android_auto: action.payload };
    case FilterActionType.SetAppleCarplay:
      return { ...state, apple_carplay: action.payload };
    case FilterActionType.SetBodyStyle:
      return {
        ...state,
        body_style: state.body_style.includes(action.payload as VehicleBodyType)
          ? state.body_style.filter((style) => style !== action.payload)
          : [...state.body_style, action.payload as VehicleBodyType],
      };
    case FilterActionType.SetMotorTrendScore:
      return { ...state, motor_trend_score: action.payload };
    case FilterActionType.SetCombinedMpg:
      return { ...state, combined_mpg: action.payload };
    case FilterActionType.SetFuelType:
      return {
        ...state,
        fuel_type: state.fuel_type.includes(action.payload as FuelType)
          ? state.fuel_type.filter((type) => type !== action.payload)
          : [...state.fuel_type, action.payload as FuelType],
      };
    case FilterActionType.SetHorsepower:
      return { ...state, horsepower: action.payload };
    case FilterActionType.SetMake:
      return { ...state, make: action.payload };
    case FilterActionType.SetModel:
      return { ...state, model: action.payload };
    case FilterActionType.SetPanoramicRoof:
      return { ...state, panoramic_roof: action.payload };
    case FilterActionType.SetPricing:
      return {
        ...state,
        pricing: {
          min_price: action.payload.min_price,
          max_price: action.payload.max_price,
        },
      };
    case FilterActionType.SetTransmissionType:
      return {
        ...state,
        transmission_type: action.payload as TransmissionType,
      };
    case FilterActionType.SetYearRange:
      return {
        ...state,
        year_range: {
          min_year: action.payload.min_year,
          max_year: action.payload.max_year,
        },
      };
    case FilterActionType.SetSeatingCapacity:
      return { ...state, seating_capacity: action.payload };
    case FilterActionType.CancelMotorTrendScore:
      return { ...state, motor_trend_score: false };
    case FilterActionType.ResetAll:
      return initialFilterState;
    default:
      return state;
  }
}

// This is the car finder slice.
// It contains the filter state and the dispatch function.
export const createCarFinderSlice: StateCreator<CarFinderSlice> = (set) => ({
  filters: initialFilterState,

  dispatch: (action) =>
    set((state) => ({
      filters: filterReducer(state.filters, action),
    })),

  initializeFilters: (filtersOnLoad) =>
    set(() => ({
      filters: {
        adaptive_cruise_control:
          filtersOnLoad?.adaptive_cruise_control || false,
        android_auto: filtersOnLoad?.android_auto || false,
        apple_carplay: filtersOnLoad?.apple_carplay || false,
        body_style: filtersOnLoad?.body_style || [],
        combined_mpg: filtersOnLoad?.combined_mpg || 0,
        fuel_type: filtersOnLoad?.fuel_type || [],
        horsepower: filtersOnLoad?.horsepower || 0,
        make: filtersOnLoad?.make || '',
        model: filtersOnLoad?.model || '',
        motor_trend_score: filtersOnLoad?.motor_trend_score || false,
        panoramic_roof: filtersOnLoad?.panoramic_roof || false,
        pricing: {
          max_price: filtersOnLoad?.pricing?.max_price || MaxPrice,
          min_price: filtersOnLoad?.pricing?.min_price || 0,
        },
        seating_capacity: filtersOnLoad?.seating_capacity || 0,
        transmission_type: filtersOnLoad?.transmission_type || '',
        year_range: filtersOnLoad?.year_range || {
          max_year: new Date().getFullYear() + 1,
          min_year: MinYear,
        },
      },
    })),

  resetFilters: () =>
    set(() => ({
      filters: initialFilterState,
    })),
});
