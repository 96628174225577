import { type StateCreator } from 'zustand';

import { VehicleIndexMake } from '@/lib/gql/graphql';

import {
  getVehicleModelYearsActionType,
  setModelsByMakeActionType,
  setVehicleMakesActionType,
  setYearsByModelActionType,
  MmyVehicleDataStateKey as StateKey,
  SubStateKey,
} from '@/store/constants';
import { type MmyVehicleDataSlice } from '@/store/typings';

export const createMmyVehicleDataSlice: StateCreator<
  MmyVehicleDataSlice,
  [['zustand/devtools', never]],
  []
> = (set) => ({
  setVehicleMakes: (makes) => {
    set(
      (state) => ({
        [StateKey]: {
          ...state[StateKey],
          [SubStateKey.Makes]: makes.makes,
          [SubStateKey.Models]: { ...state[StateKey][SubStateKey.Models] },
          [SubStateKey.Years]: { ...state[StateKey][SubStateKey.Years] },
        },
      }),
      false,
      setVehicleMakesActionType,
    );
  },

  setModelsByMake: (makeSlug, models) => {
    set(
      (state) => ({
        [StateKey]: {
          ...state[StateKey],
          [SubStateKey.Models]: {
            ...state[StateKey][SubStateKey.Models],
            [makeSlug]: models as { model: string; slug: string }[] | undefined,
          },
        },
      }),
      false,
      setModelsByMakeActionType,
    );
  },

  setYearsByModel: (makeSlug, modelSlug, years) => {
    set(
      (state) => ({
        [StateKey]: {
          ...state[StateKey],
          [SubStateKey.Years]: {
            ...state[StateKey][SubStateKey.Years],
            [makeSlug]: {
              ...state[StateKey][SubStateKey.Years]?.[makeSlug],
              [modelSlug]: years as number[],
            },
          },
        },
      }),
      false,
      setYearsByModelActionType,
    );
  },

  getVehicleModelYears: async (serverAction, { makeSlug, modelSlug }) => {
    const data = await serverAction({ makeSlug, modelSlug });

    set(
      (state) => ({
        [StateKey]: {
          ...state[StateKey],
          [SubStateKey.Years]: {
            ...state[StateKey][SubStateKey.Years],
            [makeSlug]: {
              ...state[StateKey][SubStateKey.Years]?.[makeSlug],
              [modelSlug]: data?.years as number[],
            },
          },
        },
      }),
      false,
      getVehicleModelYearsActionType,
    );

    return data;
  },

  getVehicleMakeModels: async (serverAction, { makeSlug }) => {
    const data = await serverAction({ makeSlug });

    set(
      (state) => ({
        [StateKey]: {
          ...state[StateKey],
          [SubStateKey.Models]: {
            ...state[StateKey][SubStateKey.Models],
            [makeSlug]: data?.models,
          },
        },
      }),
      false,
      getVehicleModelYearsActionType,
    );

    return data;
  },
  getVehicleMakes: async (serverAction) => {
    const data = await serverAction();
    return data.makes as VehicleIndexMake[];
  },
  [StateKey]: {
    [SubStateKey.Makes]: undefined,
    [SubStateKey.Models]: undefined,
    [SubStateKey.Years]: undefined,
  },
});
