import { type StateCreator } from 'zustand';

import {
  setGeoLocationActionType as actionType,
  GeoLocationStateKey as StateKey,
} from '@/store/constants';
import { type GeoLocationSlice } from '@/store/typings';

// TODO: explore separating the zip code call from the DTC call.
export const createGeoLocationSlice: StateCreator<
  GeoLocationSlice,
  [['zustand/devtools', never]],
  []
> = (set) => ({
  setGeoLocationData: async (data) => {
    set(
      () => ({
        [StateKey]: data,
      }),
      false,
      actionType,
    );
  },
  [StateKey]: {
    clientIp: '',
    countryIsoCode: '',
    zipCode: '',
  },
});
